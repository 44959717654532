<template lang="html">
<footer>

</footer>
</template>

<script lang="ts">
export default { }
</script>

<style lang="scss" scoped>
.support {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    a {
        color: #0070e0;
        &:hover {
            color: #3eb8ff;
        }
    }
}
</style>