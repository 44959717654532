import {IModalCompiler, Modal, ModalCompiled} from './modal';
import {Api, Core} from './core';
import {createApp, h, reactive} from 'vue';
import $ from "jquery";

const secret = localStorage.getItem('secret');
if (!secret) {
    Api.redirectToLogin();
}
export var modalCompilerVueConfigExtension:any = {};
function _modalCompiler(selector:any, params:any) : ModalCompiled {
    params = params || {};
    params.css = params.css || {};
    params.data = params.data || {};
    params.methods = params.methods || {};
    let wrapper:HTMLDivElement = document.createElement("div");
    let classes:any = {
        "modal": true
    };
    if (params.dialog) {
        classes['modal-dialog'] = true;
    }
    if (params.transparent) {
        classes['modal-transparent'] = true;
    }
    if (params.radius === false) {
        classes['modal-no-radius'] = true;
    }
    if (params.noMargin) {
        classes['modal-no-margin'] = true;
    }
    if (params.small && params.dialog) {
        classes['modal-dialog-small'] = true;
    } else if (params.large && params.dialog) {
        classes['modal-dialog-large'] = true;
    }
    wrapper.className = Object.keys(classes).join(' ');
    let mountPoint = document.createElement("div");
    wrapper.append(mountPoint);
    let configuration:any = {};
    if (params.data) {
        Object.keys(params.data).forEach(_ => configuration[_] = params.data[_]);
    }
    if (params.events) {
        Object.keys(params.events).forEach(_ => {
            let key = _[0].toUpperCase() + _.substring(1);
            configuration['on' + key] = params.events[_];
        });
    }
    let vueInstance = createApp({
        render: () => h(selector, configuration),
    });
    Object.keys(modalCompilerVueConfigExtension).forEach(_ => {
        vueInstance.config.globalProperties[_] = modalCompilerVueConfigExtension[_];
    });
    vueInstance.mount(mountPoint);
    return new ModalCompiled(wrapper, vueInstance);
};

function __recomputeRoute(scope:RoutingScope) {
    scope.path = [];
    scope.args = {};
    const input = window.location.hash || '';
    const hash = decodeURI(input || '');
    const match = hash.match(/^#([^\?]+)($|\?)/);
    if (match === null || match.length !== 3) {
        return;
    }
    const split = match[1].split('/');
    scope.path = split.filter(function (i) {return !!i});
    const args = hash.match(/\?(.*)$/);
    if (args === null || args.length !== 2) {
        return;
    }
    const subArgs = args[1].split('&');
    for (var i = 0; i < subArgs.length; i++) {
        const a = subArgs[i];
        const o = a.split('=');
        if (o.length > 1) {
            scope.args[o[0]] = o.slice(1)[0];
        }
    }
};

function __updateRoute(scope:RoutingScope) {
    __recomputeRoute(scope);
    if (!scope.path[0]) {
        const html = document.getElementsByTagName("html")[0];
        window.location.hash = html.getAttribute('default-hash');
    }
};
class ModalCompiler implements IModalCompiler {
    compile(selector: any, data: any) : ModalCompiled {
        return _modalCompiler(selector, data);
    }
}
export class RoutingScope {
    public args:Object = {};
    public path:Array<string> = new Array();
}
let baseAddress = Api.resolveBaseAddress();
export var core: Core = new Core(secret, baseAddress);
export var routingScope: RoutingScope = reactive(new RoutingScope());
let modalCompiler = new ModalCompiler();
export var modal = new Modal(modalCompiler);

window.$ = $;
window.addEventListener('hashchange', () => {
    __updateRoute(routingScope);
    modal.closeAll();
});
window.onload = () => {
    __updateRoute(routingScope);
};

export async function impersonate(userId:string) {
    const secret = await core.api.impersonate(userId);
    localStorage.setItem('secret', secret);
    Api.redirectToMain();
}

export const bindingReason = {
    'phone': 'Телефон',
    'email': 'E-Mail',
    'passport': 'Паспорт',
    'face': 'Биометрия',
    'person': 'Ф.И.О.',
};