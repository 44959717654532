<template lang="html">
<button v-on:click="onClicked($event)" v-bind:disabled="disabled === true ? true : null" v-bind:small="small">{{text}}</button>
</template>

<script lang="ts">
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false
        },
        text: {
            type: String,
            required: false
        },
        small: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        onClicked($event) {
            this.$emit('clicked', $event.target.value);
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    $_bg: #4893e1;
    border: none;
    background: $_bg;
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px #0000000a, 0 0 4px #0000000f;
    transition: all 0.3s ease 0s;
    &[small='false'], &:not([small]) {
        padding: 10px 20px;
    }
    &[small='true'] {
        padding: 5px 10px;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
        background: lighten($_bg, 10%);
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>