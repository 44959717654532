<template lang="html">
<div class="user-metrics">
    <hint v-if="loading === false" v-bind:text="'Метрики пользователя <b>' + (scope.user.name || scope.user.login) + '</b>'"></hint>
    <spinner v-if="loading === true"></spinner>
    <div v-if="loading !== true" class="dates">
        <div class="date-input">
            <div>Выгрузить с</div>
            <div>
                <date-time-input v-on:updated="onFromUpdated" v-on:enter="sync" v-bind:date="localFrom"></date-time-input>
            </div>
        </div>
        <div class="date-input">
            <div>по</div>
            <div>
                <date-time-input v-on:updated="onTillUpdated" v-on:enter="sync" v-bind:date="localTill"></date-time-input>
            </div>
        </div>
        <btn v-on:click="sync()" v-bind:disabled="loading ? true : null" v-bind:small="true" v-bind:text="'Выгрузить'"></btn>
        <span v-on:click="doExport('xlsx')" class="link">Экспорт</span>
    </div>
    <div v-if="scope.billing" class="billing-table">
        <div>
            <div>Операция</div>
            <div>Цена</div>
            <div>Кол-во запросов</div>
            <div>Стоимость</div>
        </div>
        <div v-for="v, k in scope.billing">
            <div>{{operations[k] || k}}</div>
            <div>{{scope.resolvePrice(k) || '&mdash;'}} &#8381;</div>
            <div>{{v}}</div>
            <div>{{scope.resolveAmount(k, v) || '&mdash;'}} &#8381;</div>
        </div>
        <div class="total-amount">
            <div hidden="1"></div>
            <div hidden="1"></div>
            <div>Итого</div>
            <div bold="1">{{scope.calculateTotalAmount() || '&mdash;'}} &#8381;</div>
        </div>
    </div>
    <div v-if="scope.metrics" class="metric-table">
        <div>
            <div>Тип поиска</div>
            <div>Всего поисков</div>
            <div>С совпадениями</div>
        </div>
        <div v-show="k in searchTypes" v-for="v, k in scope.metrics">
            <div>{{searchTypes[k]}}</div>
            <div>
                <number v-bind:value="v.totalCount"></number>
            </div>
            <div><number v-bind:value="v.hasHitsCount"></number></div>
        </div>
    </div>
    <div v-if="scope.actions" class="action-table">
        <div>
            <div>Код действия</div>
            <div>Описание действия</div>
            <div>Количество</div>            
        </div>
        <div v-for="metric in scope.actions">
            <div>{{metric.name}}</div>
            <div>{{codes[metric.name] || '&mdash;'}}</div>
            <div>
                <number v-bind:value="metric.value"></number>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {Util} from '../../util';
import {SearchTypes, User} from '../../core';
import SpinnerComponent from '../Spinner.vue';
import NumberComponent from '../Number.vue';
import ButtonComponent from '../Button.vue';
import DateTimeInputComponent from '../DateTimeInput.vue';
import HintComponent from '../Hint.vue';

class Scope {
    public userId:string = null;
    public user:User;
    public prices:Map<string, Number> = null;
    public actions:any = null;
    public metrics:any;
    public billing:any;
    public from:Date = null;
    public till:Date = null;
    public resolvePrice(code: string) : number {
        let value = this.prices[code];
        if (!value) {
            return null;
        }
        let rubs = value / 100.0;
        return rubs;
    }
    public resolveAmount(code: string, count: number) : number {
        let price = this.resolvePrice(code);
        if (price == null) {
            return null;
        }
        let result = price * count;
        return result;
    }
    public calculateTotalAmount() : number {
        let keys = Object.keys(this.billing);
        let result:number = 0;
        for (let key of keys) {
            result += this.resolveAmount(key, this.billing[key]);
        }
        return result;
    }
}

class Metric {
    public name:string = null;
    public value:string = null;
}

export default {
    created() {
        return this.sync();
    },
    data() {
        const scope = new Scope();
        scope.userId = this.userId;
        return {
            loading: true,
            scope: scope,
            codes: core.userLogCodes,
            localFrom: this.from,
            localTill: this.till,
            searchTypes: SearchTypes,
            operations: core.auth.config.user.billing.operations
        };
    },
    props: {
        userId: {
            type: String,
            required: true
        },
        from: {
            type: Date,
            required: true
        },
        till: {
            type: Date,
            required: true
        },
        noActions: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        userId() {
            this.sync();
        },
        from(value, oldValue) {
            let IsEqual = Util.getIsDatesEqual(value, oldValue);
            if (IsEqual) {
                return;
            }
            this.localFrom = value;
            this.sync();
        },
        till(value, oldValue) {
            let IsEqual = Util.getIsDatesEqual(value, oldValue);
            if (IsEqual) {
                return;
            }
            this.localTill = value;
            this.sync();
        }
    },
    methods: {
        onFromUpdated(value) {
            this.localFrom = value;
        },
        onTillUpdated(value) {
            this.localTill = value;
        },
        doExport(format:string) {
            const scope = this.scope;
            core.api.exportUserLogGrouping(scope.userId, this.localFrom, this.localTill, format);
        },
        async sync() {
            const scope = new Scope()
            scope.userId = this.userId;
            scope.from = this.localFrom;
            scope.till = this.localTill;
            this.scope = scope;
            await this.load();
        },
        async load() {
            let scope:Scope = this.scope;
            this.loading = true;
            try {
                scope.user = await core.api.getUser(scope.userId);
                let prices = await core.api.getUserPrices(scope.userId);
                scope.prices = new Map<string, Number>();
                for (let price of prices) {
                    scope.prices[price.code] = price.price;
                }
                scope.metrics = await core.api.getUserSearchMetrics(scope.userId, scope.from, scope.till);
                scope.billing = await core.api.getUserBillingGrouping(scope.userId, scope.from, scope.till);
                if (this.noActions !== true) {
                    const grouping = await core.api.getUserLogGrouping(scope.userId, scope.from, scope.till);
                    const metricArray = new Array<Metric>();
                    const metricKeys = Object.keys(grouping);
                    for (var i = 0; i < metricKeys.length; i++) {
                        const name = metricKeys[i];
                        const metric = new Metric();
                        metric.name = name;
                        metric.value = grouping[name];
                        metricArray.push(metric);
                    }
                    scope.actions = metricArray.sort((a, b) => {
                        if (a.name === b.name) {
                            return 0;
                        }
                        return a.name > b.name ? +1 : -1;
                    });
                }
            } finally {
                if (scope === this.scope) {
                    this.loading = false;
                }
            }
        }
    },
    components: {
        btn: ButtonComponent,
        hint: HintComponent,
        number: NumberComponent,
        spinner: SpinnerComponent,
        dateTimeInput: DateTimeInputComponent
    }
}
</script>

<style lang="scss" scoped>
$_border-color: #ededed;
$_border-width: 1px;
.link {
    color: #0070e0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: #3eb8ff
    }
}
.user-metrics {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.dates {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > *:not(:last-child) {
        margin-right: 20px;
    }
    > *:last-child {
        margin-left: 40px;
    }
    .date-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        > *:not(:last-child) {
            margin-right: 20px;
        }
        > div:nth-child(2) {
            border-bottom: 1px solid #000;
        }
    }
}
.billing-table {
    display: flex;
    flex-direction: column;
    > *:first-child {
        font-weight: bold;
    }
    > * {
        display: flex;
        flex-direction: row;
        &:not(:last-child) {
            border-bottom: $_border-width $_border-color solid;
        }
        > div {
            display: flex;
            flex-direction: row;
            padding: 5px 10px;
            &[bold] {
                font-weight: bold;
            }
            &:not(:last-child):not([hidden]) {
                border-right: $_border-width $_border-color solid;
            }
            &:nth-child(1) {
                width: 25%;
            }
            &:nth-child(2) {
                width: 25%;
            }
            &:nth-child(3) {
                width: 25%;
            }
            &:nth-child(4) {
                width: 25%;
            }
        }
    }
}
.metric-table {
    display: flex;
    flex-direction: column;
    > * {
        display: flex;
        flex-direction: row;
        &:first-child {
            font-weight: bold;
        }
        &:not(:last-child) {
            border-bottom: $_border-width $_border-color solid;
        }
        > * {
            padding: 5px 10px;
            &:not(:last-child) {
                border-right: $_border-width $_border-color solid;
            }
        }
        > *:nth-child(1) {
            width: 30%;
        }
        > *:nth-child(2) {
            width: 35%;            
        }
        > *:nth-child(3) {
            width: 35%;
        }
    }
}
.action-table {
    display: flex;
    flex-direction: column;
    > * {
        $_border-color: #ededed;
        $_border-width: 1px;
        display: flex;
        flex-direction: row;
        &:first-child {
            font-weight: bold;
        }
        &:not(:last-child) {
            border-bottom: $_border-width $_border-color solid;
        }
        > * {
            padding: 5px 10px;
            &:not(:last-child) {
                border-right: $_border-width $_border-color solid;
            }
        }
        > *:nth-child(1) {
            width: 30%;
        }
        > *:nth-child(2) {
            width: 50%;            
        }
        > *:nth-child(3) {
            width: 20%;
        }
    }
}
</style>