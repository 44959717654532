import $ from "jquery";

let itemStyle = {
    padding: "4px 10px",
    cursor: "pointer",
    "min-width": "100px",
    "text-decoration": "none",
    color: "#000",
};
let style = {
    top: "55px",
    display: "none",
    "flex-direction": "column",
    position: "fixed",
    "font-family": "Arial",
    "font-size": "12px",
    "font-style": "normal",
    "background-color": "#ffffff",
    "border-color": "#dbdbdb",
    "border-style": "solid",
    "border-width": "1px",
    "border-radius": "3px",
    "box-shadow": "0 2px 4px rgb(0 0 0 / 10%)"
};
function createDropdownItem(item: any): HTMLElement {
    let itemElement = $("<a></a>").html(item.name);
    itemElement.attr("href", item.link);
    itemElement.css({ ...itemStyle, ...item.styles });
    if (item.active) {
        $(itemElement).css("background-color", "#ededed");
    } else {
        itemElement
            .mouseover(function() {
                $(this).css("background-color", "#ededed");
            })
            .mouseout(function() {
                $(this).css("background-color", "transparent");
            });
    }
    return itemElement[0];
}

function onBindOrUpdate(el: HTMLElement, binding: any) {
    let fullId = "dropdown__" + binding.value.id;
    $("#" + fullId).remove();
    let dropdown = $('<div class="dropdown" style="display: none"></div>');
    dropdown.attr("id", fullId);
    dropdown.css({ ...style, ...binding.value.styles });

    let outerClickEventId = "click." + fullId;
    for (let i = 0; i < binding.value.items.length; i++) {
        let item = binding.value.items[i];
        let itemElement = createDropdownItem(item);
        dropdown.append(itemElement);
        $(itemElement).bind("click", event => {
            event.stopPropagation();
            if (item.onClick instanceof Function) {
                item.onClick();
            }
            dropdown.css("display", "none");
        });
    }

    $(el).on("click.dropdown", event => {
        let offset = $(el).offset();
        let height = $(el).height();
        let marginTop = binding.value.marginTop || 0;
        if (binding.value.styles.left) {
            dropdown.css('left', offset.left + 'px');
        } else {
            dropdown.css('left', offset.left - 100 + 'px');
        }
        dropdown.css("top", (binding.value.staticTop || offset.top + height + marginTop) + "px");
        event.preventDefault();
        $(window).off(outerClickEventId);
        dropdown.css("display", "flex");
        setTimeout(() => {
            $(window).on(outerClickEventId, () => {
                dropdown.css("display", "none");
                $(window).off(outerClickEventId);
            });
        }, 1);
    });

    $(el).append(dropdown);
}

export default {
    mounted: onBindOrUpdate,
    unmounted: onBindOrUpdate
};
