<template>
    <spinner v-if="loading === true"></spinner>
    <div v-if="loading === false">
        <VMarkdownView :mode="'light'" :content="content" />
    </div>
</template>

<script>
import { core } from "../../root";
import { VMarkdownView } from "vue3-markdown";
import "vue3-markdown/dist/style.css";
import Spinner from "../Spinner.vue";

export default {
    data() {
        return {
            content: "",
            loading: true
        };
    },
    methods: {
        async getDocumentationData() {
            let data = await core.api.getDocumentation(this.$props.role);
            if (data !== null) {
                this.content = data;
            }
        }
    },
    async mounted() {
        try {
            await this.getDocumentationData();
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    },
    components: {
        Spinner,
        VMarkdownView
    },
    props: {
        role: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
