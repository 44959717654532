import $ from "jquery";

function getParent(bindingValue:any) {
    if (bindingValue.scrollOf) {
        return $(bindingValue.scrollOf);
    }
    return $(window);
}

export default {
    mounted: function (el:HTMLElement, binding:any) {
        function getDocument() {
            if (binding.value.scrollFor) {
                return $(binding.value.scrollFor);
            }
            return $(document);
        }
        const span = 250;
        const parent = getParent(binding.value);
        const doc = getDocument();
        const handler = function() {
            const visible = $(el).is(':visible');
            if (visible !== true) {
                return;
            }
            const bottom = parent.scrollTop() + window.innerHeight;
            const border = doc.height() - span;
            if (bottom > border) {
                binding.value.scroll();
            }
        };
        parent.bind('scroll.infinityScroll', handler);
    },
    unmounted: function (_:HTMLElement, binding:any) {
        getParent(binding.value).off('scroll.infinityScroll');
    }
};