import { RecordPerson } from "./core";

export class Util {
    public static generateRandomId() : string {
        return (Math.random() + 1).toString(36).substring(2);
    }
    public static async fileToBase64(file: Blob) : Promise<string> {
        const toBase64Url = file => new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const r : any = reader.result;
                resolve(r);
            }
            reader.onerror = error => reject(error);
        });
        const prefix = ';base64,';
        const base64Url : string  = await toBase64Url(file);
        const index = base64Url.indexOf(prefix);
        return base64Url.substring(index + prefix.length);
    }
    public static async imageToBase64(content:any) : Promise<string> {
        let result:string;
        if (typeof content === 'string') {
            result = content;
        } else if (content instanceof File) {
            result = await Util.fileToBase64(content);
        } else {
            throw new Error('Unkonwn image format: ' + result);
        }
        return result;
    }
    public static getIsDatesEqual(date1:any, date2:any) : boolean {
        if (date1 instanceof Date === false) {
            return false;
        }
        if (date2 instanceof Date === false) {
            return false;
        }
        let time1 = (date1 as Date).getTime();
        let time2 = (date2 as Date).getTime();
        if (time1 === time2) {
            return true;
        }
        return false;
    }
    public static getHasMedia(person:RecordPerson) : boolean {
        if (person.files != null && person.files.length > 0) {
            return true;
        }
        if (person.photos != null && person.photos.length > 0) {
            return true;
        }
        if (!!person.fable) {
            return true;
        }
        return false;
    }
    public static addDays(date: Date, days: number) : Date {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}