<template lang="html">
<div v-on:click="scrollUp" id="scroll-up" style="display: none">
    <div class="scroll-up-area">
        <div class="fa fa-angle-up"></div>
        <div>Наверх</div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    methods: {
        scrollUp: function () {
            $("html, body").animate({scrollTop: 0}, "fast");
        }
    },
    mounted() {
        const jWindow = $(window);
        const jElement = $(this.$el);
        function onScroll() {
            const scollTop = jWindow.scrollTop();
            if (scollTop < 100) {
                jElement.css({display: 'none'});
            } else {
                const offset = $('main').offset();
                if (offset.left < 100) {
                    jElement.css({display: 'none'});
                } else {
                    const width = offset.left + 'px';
                    const jHeight = window.innerHeight*1.5;
                    const opacity = scollTop < jHeight ? scollTop/jHeight : 1;
                    jElement.css({width: width, display: '', opacity: opacity});
                }
            }
        }
        this.handler = $(window).bind('scroll resize', onScroll);
    },
    beforeDestroy() {
        $(window).unbind('scroll resize', this.handler);
    }
}
</script>

<style lang="scss" scoped>
#scroll-up {
    position: fixed;
    left: 0;
    top: 50px;
    width: 0px;
    height: 100%;
    cursor: pointer;
    background: transparent;
    font-family: Arial;
    &:hover {
        .scroll-up-area {
            background: rgba(65, 96, 255, 0.05);
        }
    }
    .scroll-up-area {
        display: flex;
        flex-direction: row;
        padding: 20px 10px 0 10px;;
        width: 100px;
        height: 100%;
        font-size: 120%;
        font-weight: normal;
        > * {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
</style>