<template lang="html">
<div v-bind:light="light ? true : null" class="hint">
    <div v-html="text"></div>
</div>
</template>

<script lang="ts">
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        light: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
.hint {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    color: #747474;
    &:not([light]) {
        border: 1px solid #b0ddff;
        background: #E8F4FD;
    }
    &[light] {
        text-transform: uppercase;
    }
}
</style>