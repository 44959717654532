class ModalWindow {
	public compiled: any;
	public instance:any;
	public source: any
	public onClose:Function = null;
	public disableOverlayClickClose:boolean = false;
	public previousOverlayScroll:number = null;
	constructor(compiled:any, instance:any) {
		this.compiled = compiled;
		this.instance = instance;
	}
	public hide () {
		this.compiled.style.display = 'none';
	};
	public show () {
		this.compiled.style.display = '';
	};
}
export class ModalCompiled {
	public element:HTMLElement;
	public instance:any;
	constructor(element: HTMLElement, instance: any) {
		this.element = element;
		this.instance = instance;
	}
}
export interface IModalCompiler {
	compile(selector:any, data:any) : ModalCompiled;
}
export class Modal {
	private compiler:IModalCompiler;
	private stack: Array<ModalWindow> = [];
	private overlay: HTMLDivElement|null = null;
	constructor(compiler:IModalCompiler) {
		this.compiler = compiler;
	}
	public open(selector, data) : Function {
		const compiled = this.compiler.compile(selector, data);
		const modalWin = Modal.create(compiled);
		if (!this.overlay) {
			this.createOverlay();
		} else {
			modalWin.previousOverlayScroll = this.overlay.scrollTop;
		}
		modalWin.onClose = data.onClose || null;
		if (data.disableOverlayClickClose === true) {
			modalWin.disableOverlayClickClose = true;
		}
		this.stack.forEach(_ => _.hide());
		this.stack.push(modalWin);
		this.overlay.appendChild(modalWin.compiled);
		$(modalWin.compiled).css('display', 'none').fadeIn(250);
		return () => {
			this.destroyModal(modalWin);
		};
	}
	public closeAll() : void {
		while (true) {
			let isClosed = this.closeTopModal();
			if (isClosed !== true) {
				break;
			}
		}
	}
	private closeTopModal() {
		if (this.stack.length === 0) {
			return false;
		}
		let idx = this.stack.length - 1;
		let top = this.stack[idx];
		if (top.disableOverlayClickClose === true) {
			return false;
		}
		this.destroyModal(top);
		this.stack.splice(idx, 1);
		if (this.stack.length !== 0) {
			this.stack[idx - 1].show();
		}
		return true;
	}
	private destroyModal(modal: ModalWindow) {
		modal.instance.unmount();
		const index = this.stack.indexOf(modal);
		if (index !== -1) {
			this.stack.splice(index, 1);
		}
		if (modal.onClose instanceof Function) {
			modal.onClose();
		}
		if (this.overlay !== null) {
			this.overlay.removeChild(modal.compiled);
		}
		if (this.stack.length === 0) {
			this.removeOverlay();
		} else {
			this.stack[this.stack.length - 1].show();
			if (modal.previousOverlayScroll) {
				for (var i = 0; i < 2; i++)  {
					setTimeout(() => this.overlay.scrollTop = modal.previousOverlayScroll, 10*i);
				}
			}
		}
	}
	private createOverlay() : void {
		this.overlay = document.createElement('div');
		this.overlay.setAttribute('class', 'modal-overlay');
		this.overlay.onclick = (event) => {
			if (event === null) {
				return;
			}
			if (event.target === null) {
				return;
			}
			if (event.target.parentNode === this.overlay) {
				this.closeTopModal();
			}
		};
		document.body.appendChild(this.overlay);
		document.body.style.overflow = 'hidden';
	}
	private removeOverlay() {
		if (this.overlay === null) {
			return;
		}
		document.body.style.overflow = 'auto';
		this.overlay.remove();
		this.overlay = null;
	}
	private static create(compiled:ModalCompiled) : ModalWindow {
		const wrapper = document.createElement('div');
		wrapper.appendChild(compiled.element);
		const modal = new ModalWindow(wrapper, compiled.instance);
		modal.source = compiled;
		return modal;
	}
}