<template>
    <div class="toast-container">
        <transition-group name="toasts">
            <div v-for="toast in toasts" :key="toast.id" class="toast" :class="toast.type">
                <div class="toast-content">
                    <div class="toast-title">{{ toast.title }}</div>
                    <div class="toast-message">{{ toast.message }}</div>
                </div>
                <button class="toast-close" @click="removeToast(toast.id)">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts">
import { ref } from "vue";

type ToastType = "info" | "error" | "success";

class Toast {
    public id: number;
    public message: string | null = null;
    public type: ToastType = "info";

    constructor(id: number, message: string, type: ToastType) {
        this.id = id;
        this.message = message;
        this.type = type;
    }
}
export default {
    data() {
        return {
            toasts: [],
            toastId: 0
        };
    },
    methods: {
        addToast(message: string, type: ToastType) {
            const toast = new Toast(this.toastId++, message, type);
            this.toasts.unshift(toast);
            setTimeout(() => {
                this.removeToast(toast.id);
            }, 5000);
        },
        removeToast(id: number) {
            this.toasts = this.toasts.filter((toast: { id: number }) => toast.id !== id);
        }
    }
};
</script>

<style lang="scss" scoped>
.toast-container {
    position: fixed;
    top: 55px;
    z-index: 10005;
    left: 50%;
    transform: translateX(-50%);
}

.toast {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    padding: 10px;
}

.toast .toast-content {
    flex-grow: 1;
}

.toast .toast-title {
    font-weight: bold;
}

.toast .toast-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
}

.toast.info {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.toast.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.toast.error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.toasts-item {
    display: inline-block;
    margin-right: 10px;
}
.toasts-enter-active,
.toasts-leave-active {
    transition: all 0.5s ease;
}
.toasts-enter-from,
.toasts-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
.toasts-move {
    transition: transform 0.5s ease;
}
</style>
