<template lang="html">
<div v-bind:disabled="(disabled || loading === true) ? true : null" v-on:click="onClick" class="checkbox">
    <span v-if="loading === true" class="fas fa-sync-alt fa-spin"></span>
    <span v-if="flags[name] === true && loading !== true" class="far fa-check-square"></span>
    <span v-if="flags[name] !== true && loading !== true" class="far fa-square"></span>
    <span>{{title}}</span>
</div>
</template>

<script lang="ts">
import {RecordFlags, CompanyFlags} from '../../core'

export default {
    props: {
        flags: {
            type: [RecordFlags, CompanyFlags],
            required: true
        },
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        handler: {
            type: Function,
            required: false
        },
        loading: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        async onClick() {
            if (this.disabled || this.loading === true) {
                return;
            }
            let name = this.name as string;
            this.flags[name] = !this.flags[name];
            if (this.handler) {
                let promise = this.handler();
                if (promise instanceof Promise) {
                    await promise;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px 0;
    > *:not(:last-child) {
        margin-right: 10px;
    }
    &[disabled] {
        pointer-events: none;
        opacity: 0.5;
    }
    .fas, .far {
        width: 12px;
        height: 12px;
    }
}
</style>