<template lang="html">
<span>{{prefix}}{{result}}</span>
</template>

<script lang="ts">
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        mono: {
            type: Boolean,
            required: false
        },
        prefix: {
            type: String,
            required: false
        }
    },
    computed: {
        result() {
            let strValue = this.value.toString();
            if (this.mono === true)  {
                return strValue;
            }
            let result = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>