<template lang="html">
<span>{{result}}</span>
</template>

<script lang="ts">
export default {
    props: ['value', 'minYear', 'stub', 'noTime'],
    computed: {
        result: function () {
            function getLeadingZero(value) {
                return ('0' + value).slice(-2);
            }
            const date = new Date(this.value);
            const year = date.getFullYear();
            const minYear = parseInt(this.minYear);
            const minYearNan = isNaN(minYear);
            if (minYearNan === false && year < minYear) {
                return this.stub || '';
            }
            const day = getLeadingZero(date.getDate());
            const month = getLeadingZero(date.getMonth() + 1);
            const hour = getLeadingZero(date.getHours());
            const minute = getLeadingZero(date.getMinutes());
            let result = day + '.' + month + '.' + year;
            if (this.noTime !== true) {
                result = result + " " + hour + ':' + minute
            }
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>