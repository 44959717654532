<template>
    <div></div>
</template>

<script lang="ts">
export default {};
</script>

<style lang="scss" scoped>
.logo {
    $_margin: 15px;
    position: fixed;
    top: 7px;
    left: $_margin;
    z-index: 100001;
}
</style>
