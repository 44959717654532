<template lang="html">
<span class="human-id">
    <a v-if="!!href" v-bind:href="href"><number v-bind:value="value" v-bind:prefix="prefix" v-bind:mono="true"></number></a>
    <number v-if="!href" v-bind:value="value" v-bind:prefix="prefix" v-bind:mono="true"></number>
</span>
</template>

<script lang="ts">
import NumberComponent from '../Number.vue';

export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        prefix: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: false
        }
    },
    components: {
        number: NumberComponent
    }
}
</script>

<style lang="scss" scoped>
.human-id {
    display: inline-flex;
    flex-direction: row;
}
</style>