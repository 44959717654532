<template lang="html">
<span v-bind:stub="isStub ? true : null" v-bind:title="value" v-bind:monospace="monospace === true ? true : null">{{result}}</span>
</template>

<script lang="ts">
export default {
    props: ['value', 'max-length', 'stub', 'monospace'],
    computed: {
        isStub: function () {
            const value = typeof this.value === 'string' ? this.value : '';
            return value.length === 0 && typeof this.stub === 'string';
        },
        result: function () {
            const value = typeof this.value === 'string' ? this.value : '';
            if (value.length === 0 && typeof this.stub === 'string') {
                return this.stub;
            }
            const length = typeof this.maxLength === 'number' ? this.maxLength : value.length;
            if (length >= value.length) {
                return value;
            }
            return value.substring(0, length - 1) + '...';
        }
    }
}
</script>

<style lang="scss" scoped>
*[monospace] {
    font-family: monospace;
}
</style>