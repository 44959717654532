<template lang="html">
<div class="spinner">
    <div v-bind:white="white ? true : null" class="fas fa-sync-alt fa-spin"></div>
</div>
</template>

<script lang="ts">
export default {
    props: {
        white: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.spinner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
}
.fas {
    font-size: 25px;
    &:not([white]) {
        color: #666;
    }
    &.white {
        color: #ccc;
    }
}
img {
    -webkit-animation: fadein 1s ease-in alternate infinite;
    -moz-animation: fadein 1s ease-in alternate infinite;
    animation: fadein 1s ease-in alternate infinite;
}
@-webkit-keyframes fadein {
    from {opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
</style>