<template lang="html">
<div>
    <div class="date-time-input">
        <div>
            <input v-model="day" v-on:keydown="onKeyDown" v-on:focus="onInputFocus" type="text" placeholder="дд" class="width-two day" />
        </div>
        <div class="dot">.</div>
        <div>
            <input v-model="month" v-on:keydown="onKeyDown" v-on:focus="onInputFocus" type="text" placeholder="мм" class="width-two month" />
        </div>
        <div class="dot">.</div>
        <div>
            <input v-model="year" v-on:keydown="onKeyDown" v-on:focus="onInputFocus" type="text" placeholder="гггг" class="width-four year" />
        </div>
        <div class="space"></div>
        <div>
            <input v-model="hour" v-on:keydown="onKeyDown" v-on:focus="onInputFocus" type="text" placeholder="чч" class="width-two hour" />
        </div>
        <div class="dot">:</div>
        <div>
            <input v-model="minute" v-on:keydown="onKeyDown" v-on:focus="onInputFocus" type="text" placeholder="мм" class="width-two minute" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import $ from 'jquery';

function getPadded(value:number, length:number) : string {
    const strValue = value.toString();
    return strValue.padStart(length, '0');
}

function getIsMatch(stringValue:string, actualValue:number) : boolean {
    if (!stringValue) {
        return false;
    }
    const parsed = parseInt(stringValue);
    if (parsed === actualValue) {
        return true;
    }
    return false;
}

function syncDate(scope:any, date:Date) : void {
    const year = date.getUTCFullYear();
    const isYearMatch = getIsMatch(scope.year, year);
    if (isYearMatch === false) {
        scope.year = getPadded(year, 4);
    }
    const month = date.getUTCMonth() + 1;
    const isMonthMatch = getIsMatch(scope.month, month);
    if (isMonthMatch === false) {
        scope.month = getPadded(month, 2);
    }
    const day = date.getUTCDate();
    const isDayMatch = getIsMatch(scope.day, day);
    if (isDayMatch === false) {
        scope.day = getPadded(day, 2);
    }
    const hour = date.getUTCHours()
    const isHourMatch = getIsMatch(scope.hour, hour);
    if (isHourMatch === false) {
        scope.hour = getPadded(hour, 2);
    }
    const minutes = date.getUTCMinutes();
    const isMinutesMatch = getIsMatch(scope.minute, minutes);
    if (isMinutesMatch === false) {
        scope.minute = getPadded(minutes, 2);
    }
}

export default {
    data() {
        const data = {
            day: null,
            month: null,
            year: null,
            hour: null,
            minute: null
        };
        if (!this.date) {
            return data;
        }
        let date:Date = new Date(this.date);
        syncDate(data, date);
        return data;
    },
    props: {
        date: {
            type: Date,
            required: false
        }
    },
    watch: {
        date(value) {
            let date:Date = new Date(value);
            syncDate(this, date);
        },
        day(value) {
            const numeric = parseInt(value);
            if (numeric > 31) {
                this.day = '0' + numeric.toString().substring(0, 1);
            } else if (value === '00') {
                this.day = '01';
            }
            if (this.day.length === 2) {
                this.switchTo('month');
            }
            this.tryBuildDate();
        },
        month(value) {
            const numeric = parseInt(value);
            if (numeric > 12) {
                this.month = '0' + numeric.toString().substring(0, 1);
            } else if (value === '00') {
                this.month = '01';
            }
            if (this.month.length === 2) {
                this.switchTo('year');
            }
            this.tryBuildDate();
        },
        year(value) {
            if (value === '0000') {
                this.year = '1900';
            }
            if (this.year.length === 4) {
                this.switchTo('hour');
            }
            this.tryBuildDate();
        },
        hour(value) {
            const numeric = parseInt(value);
            if (numeric > 24) {
                this.hour = '0' + numeric.toString().substring(0, 1);
            }
            if (this.hour.length === 2) {
                this.switchTo('minute');
            }
            this.tryBuildDate();
        },
        minute(value) {
            const numeric = parseInt(value);
            if (numeric > 59) {
                this.minute = '0' + numeric.toString().substring(0, 1);
            }
            this.tryBuildDate();
        }
    },
    methods: {
        tryBuildDate() {
            let date:Date;
            let year = parseInt(this.year);
            let month = parseInt(this.month);
            let day = parseInt(this.day);
            if (
                year && year > 1900 && year < 2050 &&
                month && month > 0 && month < 13 &&
                day && day > 0 && day < 32
            ) {
                let hour = parseInt(this.hour);
                let minute = parseInt(this.minute);
                let dateValue:number;
                dateValue = Date.UTC(year, month - 1, day, hour || 0, minute || 0, 0);
                date = new Date(dateValue);
            }
            if (date) {
                this.$emit('updated', date);
            } else {
                this.$emit('incorrect');
            }
        },
        switchTo(input:string) {
            $(this.$el).find('.' + input).trigger('focus')
        },
        onInputFocus($event:FocusEvent) : void {
            $($event.target).trigger('select');
        },
        onKeyDown($event:KeyboardEvent) : void {
            if ($event.code === 'Delete') {
                return;
            }
            if ($event.code === 'Backspace') {
                return;
            }
            if ($event.code === 'Escape') {
                return;
            }
            if ($event.code === 'Tab') {
                return;
            }
            if ($event.code === 'Enter') {
                this.$emit('enter');
            }
            const isMatch = $event.key.match(/^((Arrow\w+|F\d+)|[0-9_])$/i);
            if (!isMatch) {
                $event.preventDefault();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$_font-family: monospace;
$_font-size: 12px;
.date-time-input {
    display: flex;
    flex-direction: row;
    font-family: $_font-family;
    .dot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }
    .space {
        width: 2px;
    }
}
.width-two {
    width: 20px;
}
.width-four {
    width: 35px;
}
input {
    border: none;
    padding: 0;
    height: 25px;
    text-align: center;
    font-size: $_font-size;
    font-family: $_font-family;
    &:focus {
        outline: none;
    }
}
</style>